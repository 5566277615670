.profile-setup-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(#feac5e 0%, #c779d0 50%, #4bc0c8 100%);
  }
  .profile-setup-container01 {
    flex: 0 0 auto;
    width: 90%;
    height: auto;
    margin: var(--dl-space-space-doubleunit);
    display: flex;
    padding: var(--dl-space-space-doubleunit);
    max-width: 1400px;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
  }
  .profile-setup-text {
    color: var(--dl-color-primary-100);
    font-size: 48px;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    text-align: center;
  }
  .profile-setup-text01 {
    font-style: normal;
    font-weight: 400;
  }
  .profile-setup-text03 {
    font-size: 28px;
    align-self: center;
    font-style: normal;
    font-weight: 300;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .profile-setup-container02 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
  }
  .profile-setup-icon {
    fill: var(--dl-color-primary-mainblue);
    width: 50px;
    height: auto;
    align-self: center;
  }
  .profile-setup-text04 {
    color: var(--dl-color-primary-500);
    margin: var(--dl-space-space-halfunit);
    align-self: center;
    text-align: center;
    font-weight: 600;
  }
  .profile-setup-ride-type {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .profile-setup-ride {
    flex: 0 0 auto;
    width: 200px;
    display: flex;
    padding: var(--dl-space-space-unit);
    transition: 0.3s;
    align-items: center;
    border-color: var(--dl-color-gray-black);
    border-width: 0px;
    flex-direction: column;
    margin: var(--dl-space-space-halfunit);
  }
  .profile-setup-ride-select {
    flex: 0 0 auto;
    width: 200px;
    display: flex;
    padding: var(--dl-space-space-unit);
    transition: 0.3s;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    border-width: 1px;
    margin: var(--dl-space-space-halfunit);
  }
  .profile-setup-image {
    width: 100px;
    object-fit: cover;
  }
  .profile-setup-text05 {
    font-size: 24px;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-doubleunit);
    margin-right: var(--dl-space-space-doubleunit);
  }
  .profile-setup-image1 {
    width: 100px;
    object-fit: cover;
  }
  .profile-setup-text06 {
    font-size: 24px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .profile-setup-icon02 {
    fill: var(--dl-color-gray-black);
    width: 100px;
    height: auto;
    align-self: center;
  }
  .profile-setup-text07 {
    font-size: 24px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .profile-setup-container06 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
  }
  .profile-setup-icon04 {
    fill: var(--dl-color-primary-mainblue);
    width: 50px;
    height: auto;
    align-self: center;
  }
  .profile-setup-text08 {
    color: var(--dl-color-primary-500);
    margin: var(--dl-space-space-halfunit);
    align-self: center;
    text-align: center;
    font-weight: 600;
  }
  .profile-setup-ability-levels {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    transition: 0.3s;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .profile-setup-ability {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    transition: 0.3s;
    align-items: center;
    flex-direction: column;
  }

  .profile-setup-ability-select {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    transition: 0.3s;
    align-items: center;
    flex-direction: column;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 16px;
  }
  .profile-setup-icon06 {
    fill: #ff545a;
    width: 75px;
    height: 75px;
  }
  .profile-setup-text09 {
    margin-top: var(--dl-space-space-halfunit);
  }

  .profile-setup-icon08 {
    fill: #007d00;
    width: 75px;
    height: 75px;
  }
  .profile-setup-text10 {
    margin-top: var(--dl-space-space-halfunit);
  }

  .profile-setup-icon10 {
    fill: #0898f5;
    width: 75px;
    height: 75px;
  }
  .profile-setup-text11 {
    margin-top: var(--dl-space-space-halfunit);
  }

  .profile-setup-icon12 {
    width: 75px;
    height: 75px;
  }
  .profile-setup-text12 {
    margin-top: var(--dl-space-space-halfunit);
  }

  .profile-setup-container12 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .profile-setup-icon14 {
    width: 60px;
    height: 60px;
  }
  .profile-setup-icon16 {
    width: 60px;
    height: 60px;
  }
  .profile-setup-text15 {
    margin-top: var(--dl-space-space-unit);
  }
  .profile-setup-container14 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
  }
  .profile-setup-icon18 {
    width: 50px;
    height: 50px;
  }
  .profile-setup-icon20 {
    width: 60px;
    height: 60px;
  }
  .profile-setup-icon22 {
    width: 50px;
    height: 50px;
  }
  .profile-setup-text18 {
    margin-top: var(--dl-space-space-unit);
  }
  .profile-setup-container15 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
  }
  .profile-setup-icon24 {
    fill: var(--dl-color-primary-mainblue);
    width: 50px;
    height: auto;
    align-self: center;
  }
  .profile-setup-text19 {
    color: var(--dl-color-primary-500);
    margin: var(--dl-space-space-halfunit);
    align-self: center;
    text-align: center;
    font-weight: 600;
  }
  .profile-setup-container16 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
  }
  .profile-setup-textinput {
    height: 36px;
    align-self: center;
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .profile-setup-select {
    width: auto;
    height: 36px;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .profile-setup-container17 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
  }
  .profile-setup-icon26 {
    fill: var(--dl-color-primary-mainblue);
    width: 50px;
    height: auto;
    align-self: center;
  }
  .profile-setup-text22 {
    color: var(--dl-color-primary-500);
    margin: var(--dl-space-space-halfunit);
    align-self: center;
    text-align: center;
    font-weight: 600;
  }
  .profile-setup-sliders {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .profile-setup-text23 {
    font-style: normal;
    font-weight: 600;
  }
  .profile-setup-slider-container {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .profile-setup-text28 {
    font-style: normal;
    font-weight: 600;
  }
  .profile-setup-slider {
    width: 600px;
    padding: 0px;
    margin: var(--dl-space-space-halfunit);
  }
  .profile-setup-small-text {
    font-size: 14px;
  }
  .profile-setup-container20 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .profile-setup-button {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    margin-top: var(--dl-space-space-doubleunit);
    font-weight: 600;
    border-width: 0px;
    margin-right: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-primary-mainblue);
  }
  @media(max-width: 1200px) {
    .profile-setup-container01 {
      width: 90%;
      padding: 5px;
    }
    .profile-setup-text {
      color: var(--dl-color-primary-100);
      font-size: 48px;
    }
    .profile-setup-text01 {
      color: var(--dl-color-gray-black);
      font-style: normal;
      font-weight: 400;
    }
    .profile-setup-ride-type {
      width: auto;
    }
  }
  @media(max-width: 767px) {
    .profile-setup-container01 {
      width: 85%;
    }
    .profile-setup-ride-type {
      width: 100%;
    }
    .profile-setup-ride {
      width: 100px;
      height: auto;
    }
    .profile-setup-ride-select {
        width: 100px;
        height: auto;
      }
    .profile-setup-image {
      width: 50px;
    }
    .profile-setup-text05 {
      font-size: 12px;
    }
    .profile-setup-image1 {
      width: 50px;
    }
    .profile-setup-text06 {
      font-size: 12px;
    }
    .profile-setup-icon02 {
      width: 50px;
    }
    .profile-setup-text07 {
      font-size: 12px;
    }
    .profile-setup-slider {
        width: 200px;
        padding: 0px;
      }
    .profile-setup-textinput {
        width: 75%;
    }
  }
  @media(max-width: 479px) {
    .profile-setup-container01 {
      width: 90%;
      margin: var(--dl-space-space-halfunit);
    }
    .profile-setup-text {
      font-size: 24px;
      margin-top: var(--dl-space-space-halfunit);
    }
    .profile-setup-text03 {
      font-size: 14px;
    }
    .profile-setup-text04 {
      font-size: 16px;
    }
    .profile-setup-ride-type {
      margin-bottom: var(--dl-space-space-unit);
    }
    .profile-setup-ride {
      width: 80px;
    }
      .profile-setup-ride-select {
        width: 80px;
      }
    .profile-setup-text08 {
      font-size: 16px;
    }
    .profile-setup-icon06 {
      width: 25px;
      height: 25px;
    }
    .profile-setup-text09 {
      font-size: 12px;
    }
    .profile-setup-ability {
      width: 85px;
      padding: var(--dl-space-space-halfunit);
    }
    .profile-setup-ability-select {
        width: 85px;
        padding: var(--dl-space-space-halfunit);
      }
    .profile-setup-icon08 {
      width: 25px;
      height: 25px;
    }
    .profile-setup-text10 {
      font-size: 12px;
    }
    .profile-setup-icon10 {
      width: 25px;
      height: 25px;
    }
    .profile-setup-text11 {
      font-size: 12px;
    }
    .profile-setup-icon12 {
      width: 25px;
      height: 25px;
    }
    .profile-setup-text12 {
      font-size: 12px;
    }
    .profile-setup-icon14 {
      width: 20px;
      height: 20px;
    }
    .profile-setup-icon16 {
      width: 20px;
      height: 20px;
    }
    .profile-setup-text15 {
      font-size: 12px;
    }
    .profile-setup-icon18 {
      width: 17px;
      height: 17px;
    }
    .profile-setup-icon20 {
      width: 20px;
      height: 20px;
    }
    .profile-setup-icon22 {
      width: 17px;
      height: 17px;
    }
    .profile-setup-text18 {
      font-size: 12px;
    }
    .profile-setup-text19 {
      font-size: 16px;
    }
    .profile-setup-text22 {
      font-size: 16px;
    }
    .profile-setup-button {
      height: 34px;
      padding: var(--dl-space-space-halfunit);
      font-size: 14px;
      margin-right: 0px;
      margin-bottom: 0px;
    }
    .profile-setup-slider {
        width: 200px;
        padding: 0px;
        margin: 4px;
      }
      .profile-setup-small-text {
        font-size: 10px;
      }
  }
  