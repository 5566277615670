.reviews-reviews {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  max-width: 1500px;
  align-self: center;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: center;
  background-color: rgb(250,250,250);
}
.reviews-container {
  width: auto;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  max-width: 400px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: rgb(250,250,250);
}
.reviews-text {
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.reviews-container01 {
  width: auto;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.reviews-icon {
  fill: #ffb400;
  width: 40px;
  height: 40px;
}
.reviews-icon02 {
  fill: #ffb400;
  width: 40px;
  height: 40px;
}
.reviews-icon04 {
  fill: #ffb400;
  width: 40px;
  height: 40px;
}
.reviews-icon06 {
  fill: #ffb400;
  width: 40px;
  height: 40px;
}
.reviews-icon08 {
  fill: #ffb400;
  width: 40px;
  height: 40px;
}
.reviews-ovarall-rating {
  font-size: 24px;
  max-width: auto;
  align-self: center;
  margin-left: var(--dl-space-space-unit);
}
.reviews-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.reviews-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.reviews-container04 {
  flex: 0 0 auto;
  width: 100px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.reviews-icon10 {
  width: 20px;
  height: 20px;
}
.reviews-icon12 {
  width: 25px;
  height: 25px;
}
.reviews-icon14 {
  width: 20px;
  height: 20px;
}
.reviews-container05 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.reviews-icon16 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon18 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon20 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon22 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon24 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-ovarall-rating1 {
  font-size: 16px;
  max-width: auto;
  align-self: center;
  margin-left: var(--dl-space-space-unit);
}
.reviews-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.reviews-container07 {
  flex: 0 0 auto;
  width: 100px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.reviews-icon26 {
  width: 25px;
  height: 25px;
}
.reviews-icon28 {
  width: 25px;
  height: 25px;
}
.reviews-container08 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.reviews-icon30 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon32 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon34 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon36 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon38 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-ovarall-rating2 {
  font-size: 16px;
  max-width: auto;
  align-self: center;
  margin-left: var(--dl-space-space-unit);
}
.reviews-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
}
.reviews-container10 {
  flex: 0 0 auto;
  width: 100px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.reviews-icon40 {
  width: 34px;
  height: 34px;
}
.reviews-container11 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.reviews-icon42 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon44 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon46 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon48 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon50 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-ovarall-rating3 {
  font-size: 16px;
  max-width: auto;
  align-self: center;
  margin-left: var(--dl-space-space-unit);
}
.reviews-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
}
.reviews-container13 {
  flex: 0 0 auto;
  width: 100px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.reviews-icon52 {
  fill: #0898f5;
  width: 34px;
  height: 34px;
}
.reviews-container14 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.reviews-icon54 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon56 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon58 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon60 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon62 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-ovarall-rating4 {
  font-size: 16px;
  max-width: auto;
  align-self: center;
  margin-left: var(--dl-space-space-unit);
}
.reviews-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
}
.reviews-container16 {
  flex: 0 0 auto;
  width: 100px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.reviews-icon64 {
  fill: #007d00;
  width: 34px;
  height: 34px;
}
.reviews-container17 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.reviews-icon66 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon68 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon70 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon72 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-icon74 {
  fill: #ffb400;
  width: 34px;
  height: 34px;
}
.reviews-ovarall-rating5 {
  font-size: 16px;
  max-width: auto;
  align-self: center;
  margin-left: var(--dl-space-space-unit);
}
.reviews-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
}
.reviews-container19 {
  flex: 0 0 auto;
  width: 100px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.reviews-icon76 {
  fill: #ff545a;
  width: 34px;
  height: 34px;
}
.reviews-icon78 {
  width: 34px;
  height: 34px;
}
.reviews-icon80 {
  width: 34px;
  height: 34px;
}
.reviews-icon82 {
  width: 34px;
  height: 34px;
}
.reviews-icon84 {
  width: 34px;
  height: 34px;
}
.reviews-icon86 {
  width: 34px;
  height: 34px;
}
.reviews-container20 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.reviews-ovarall-rating6 {
  font-size: 16px;
  max-width: auto;
  align-self: center;
  margin-left: var(--dl-space-space-unit);
}
.reviews-container21 {
  flex: 0 0 auto;
  width: 100%;
  height: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: #effcff;
}
.reviews-text01 {
  font-size: 20px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
}
.reviews-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.reviews-container23 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.reviews-icon88 {
  fill: #ffb400;
  width: 25px;
  height: 25px;
}
.reviews-text03 {
  font-size: 16px;
  max-width: auto;
  align-self: center;
}
.reviews-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.reviews-container25 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.reviews-icon90 {
  fill: #ffb400;
  width: 25px;
  height: 25px;
}
.reviews-text05 {
  font-size: 16px;
  max-width: auto;
  align-self: center;
}
.reviews-container26 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.reviews-container27 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.reviews-icon92 {
  fill: #ffb400;
  width: 25px;
  height: 25px;
}
.reviews-text07 {
  font-size: 16px;
  max-width: auto;
  align-self: center;
}
.reviews-icon94 {
  width: 34px;
  height: 34px;
  margin: var(--dl-space-space-halfunit);
}
.reviews-container28 {
  width: 60%;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: 0px;
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
}
.reviews-text09 {
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.reviews-select {
  margin-bottom: var(--dl-space-space-doubleunit);
}
@media(max-width: 1200px) {
  .reviews-container28 {
    width: auto;
  }
}
@media(max-width: 479px) {
  .reviews-container {
    margin: var(--dl-space-space-doubleunit);
  }
  .reviews-container28 {
    margin: var(--dl-space-space-doubleunit);
  }
}
