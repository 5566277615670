.saved-mountain-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.saved-mountain-page-top-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgb(12, 77, 130) 0.00%,rgb(203, 254, 255) 100.00%);
}
.saved-mountain-page-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: fixed;
  align-items: flex-start;
}
.saved-mountain-page-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
}
.saved-mountain-page-main {
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1500px;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.saved-mountain-page-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  justify-content: center;
}
.saved-mountain-page-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
}
.saved-mountain-page-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.saved-mountain-page-container6 {
  flex: 0 0 auto;
  width: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-radius: 35px;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.saved-mountain-page-icon {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.saved-mountain-page-textinput {
  color: #8a8a8a;
  width: 450px;
  height: 50px;
  font-size: 24px;
  transition: 0.3s;
  border-width: 0px;
  padding-left: 0px;
  padding-bottom: 7px;
  background-color: var(--dl-color-gray-900);
}
.saved-mountain-page-textinput:focus {
  outline: none;
}
.saved-mountain-page-textinput:hover {
  width: 515;
}
.saved-mountain-page-link {
  display: contents;
}
.saved-mountain-page-icon2 {
  fill: var(--dl-color-primary-100);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.saved-mountain-page-icon4 {
  width: 40px;
  height: 40px;
}
.saved-mountain-page-container7 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 35px;
  justify-content: flex-start;
}
.saved-mountain-page-sort-or-filter-indicator {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-primary-700);
  border-width: 1px;
  border-radius: 24px;
}
.saved-mountain-page-text {
  color: var(--dl-color-primary-500);
  font-size: 24px;
  font-family: Poppins;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
.saved-mountain-page-sort-or-filter-indicator1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-primary-700);
  border-width: 1px;
  border-radius: 24px;
}
.saved-mountain-page-text1 {
  color: var(--dl-color-primary-500);
  font-size: 24px;
  font-family: Poppins;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
.saved-mountain-page-container8 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: center;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  min-height: 90vh;
  display: flex;
  justify-content: flex-start;
}
.saved-mountain-page-cards-container {
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  flex-wrap: wrap;
  max-width: 1100px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.saved-mountain-page-text2 {
  width: 100%;
  align-self: flex-start;
  font-style: normal;
}
.saved-mountain-page-cards-container1 {
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  flex-wrap: wrap;
  max-width: 1000px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.saved-mountain-page-text5 {
  width: 100%;
  align-self: flex-start;
  font-style: normal;
}
@media(max-width: 1200px) {
  .saved-mountain-page-container4 {
    width: 100%;
  }
  .saved-mountain-page-button {
    display: flex;
    border-color: var(--dl-color-gray-white);
    flex-direction: row;
  }
}
@media(max-width: 991px) {
  .saved-mountain-page-main {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .saved-mountain-page-container5 {
    flex-wrap: wrap;
  }
  .saved-mountain-page-cards-container {
    justify-content: center;
  }
  .saved-mountain-page-cards-container1 {
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .saved-mountain-page-main {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .saved-mountain-page-container4 {
    padding: var(--dl-space-space-halfunit);
  }
  .saved-mountain-page-container8 {
    padding: 0px;
  }
  .saved-mountain-page-text2 {
    margin-left: var(--dl-space-space-doubleunit);
    font-size: 24px;
  }
  .saved-mountain-page-text5 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .saved-mountain-page-textinput {
    width: 100%;
    height: 34px;
    font-size: 14px;
  }
  .form {
    width: 100%;
  }
  .saved-mountain-page-container6 {
    margin: var(--dl-space-space-halfunit);
    border-radius: 10px;
    width: 100%;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .saved-mountain-page-textinput {
    width: 300px;
    height: 34px;
    font-size: 16px;
  }
  .saved-mountain-page-text2 {
    font-size: 1.5rem;
    text-align: center;
    margin-left: 0px;
  }
  .saved-mountain-page-text5 {
    font-size: 1.5rem;
    text-align: center;
    margin-left: 0px;
  }
}
