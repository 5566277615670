.nav-bar-private-header {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  padding-top: 15px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 15px;
  justify-content: space-between;
  background-color: #ffffff;
}
.nav-bar-private-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.nav-bar-private-text {
  font-size: 24px;
  font-family: Poppins;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.nav-bar-private-text1 {
  font-size: 28px;
  font-weight: 900;
}
.nav-bar-private-nav {
  display: flex;
}
.nav-bar-private-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.nav-bar-private-sign-up {
  color: var(--dl-color-primary-100);
  font-style: normal;
  font-weight: 700;
  border-color: var(--dl-color-gray-700);
  border-radius: 15px;
  background-color: var(--dl-color-gray-white);
}
.nav-bar-private-sign-up-mobile {
    color: var(--dl-color-primary-100);
    font-weight: 500;
    border-color: var(--dl-color-gray-700);
    border-radius: 10px;
    background-color: var(--dl-color-gray-white);
    font-size: 14px;
    margin-bottom: var(--dl-space-space-halfunit);
  }
.nav-bar-private-burger-menu {
  display: none;
}
.nav-bar-private-icon {
  width: 16px;
  height: 16px;
}
.nav-bar-private-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}
.nav-bar-private-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nav-bar-private-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  justify-content: space-between;
}
.nav-bar-private-image {
  height: 2rem;
}
.nav-bar-private-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-bar-private-icon02 {
  width: 16px;
  height: 16px;
}
.nav-bar-private-icon04 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.nav-bar-private-icon06 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.nav-bar-private-icon08 {
  width: 16px;
  height: 16px;
}
@media(max-width: 767px) {
  .nav-bar-private-header {
    padding-left: 32px;
    padding-right: 32px;
  }
  .nav-bar-private-nav {
    display: none;
  }
  .nav-bar-private-btn-group {
    display: none;
  }
  .nav-bar-private-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .nav-bar-private-header {
    padding: var(--dl-space-space-unit);
    padding-right: 1em;
  }
  .nav-bar-private-mobile-menu {
    padding: 16px;
  }
}
