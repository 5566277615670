.stats-stats {
  width: 100%;
  display: grid;
  padding: 48px;
  grid-gap: 32px;
  position: relative;
  transition: 0.3s;
  background-color: #f3f3f3;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.stats-stats:hover {
  padding-top: 55px;
  padding-bottom: 55px;
}
.stats-stat {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.stats-text {
  color: var(--dl-color-primary-500);
  font-size: 3rem;
}
.stats-subtext {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.stats-description {
    color: var(--dl-color-gray-500);
    font-size: 0.75rem;
    margin-top: var(--dl-space-space-halfunit);
    text-align: center;
}
@media(max-width: 991px) {
  .stats-text {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .stats-stats {
    padding-left: 32px;
    padding-right: 32px;
    grid-template-columns: 1fr 1fr;
  }
  .stats-text01 {
    text-align: center;
  }
  .stats-text04 {
    text-align: center;
  }
  .stats-text07 {
    text-align: center;
  }
  .stats-text10 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .stats-stats {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
    grid-template-columns: 1fr 1fr;
  }
  .stats-text {
    font-size: 1rem;
  }
  .stats-subtext {
    font-size: 0.75rem;
  }
  .stats-description {
    font-size: 0.5rem;
  }
}
