.rating-distribution-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.rating-distribution-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
}
.rating-distribution-text {
  align-self: center;
  margin-right: var(--dl-space-space-halfunit);
  width: 25%;
}
.rating-distribution-container02 {
  flex: 0 0 auto;
  width: 70%;
  height: 30px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
}

.rating-distribution-bar {
    background-color: var(--dl-color-primary-mainblue);
    height: 100%;
}

.rating-distribution-container03 {
  flex: 0 0 auto;
  width: 10%;
  height: 30px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rating-distribution-text01 {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  width: 15%;
}
.rating-distribution-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
}
.rating-distribution-text02 {
  align-self: center;
  margin-right: var(--dl-space-space-unit);
}
.rating-distribution-container05 {
  flex: 0 0 auto;
  width: 10%;
  height: 30px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-primary-mainblue);
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rating-distribution-container06 {
  flex: 0 0 auto;
  width: 50%;
  height: 30px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rating-distribution-text03 {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
}
.rating-distribution-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
}
.rating-distribution-text04 {
  align-self: center;
  margin-right: var(--dl-space-space-unit);
}
.rating-distribution-container08 {
  flex: 0 0 auto;
  width: 0%;
  height: 30px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-primary-mainblue);
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rating-distribution-container09 {
  flex: 0 0 auto;
  width: 60%;
  height: 30px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rating-distribution-text05 {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
}
.rating-distribution-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
}
.rating-distribution-text06 {
  align-self: center;
  margin-right: var(--dl-space-space-unit);
}
.rating-distribution-container11 {
  flex: 0 0 auto;
  width: 0%;
  height: 30px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-primary-mainblue);
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rating-distribution-container12 {
  flex: 0 0 auto;
  width: 60%;
  height: 30px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rating-distribution-text07 {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
}
.rating-distribution-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
}
.rating-distribution-text08 {
  align-self: center;
  margin-right: var(--dl-space-space-unit);
}
.rating-distribution-container14 {
  flex: 0 0 auto;
  width: 0%;
  height: 30px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-primary-mainblue);
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rating-distribution-container15 {
  flex: 0 0 auto;
  width: 60%;
  height: 30px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rating-distribution-text09 {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
}
