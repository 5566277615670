.explore-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.explore-page-top-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgb(255, 227, 255) 0.00%,rgb(211, 254, 255) 100.00%);
}
.explore-page-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: fixed;
  align-items: flex-start;
}
.explore-page-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
}
.explore-page-main {
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1500px;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.explore-page-container03 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  justify-content: flex-end;
}
.explore-page-container04 {
  flex: 0 0 auto;
  width: 375px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.explore-page-container05 {
  flex: 0 0 auto;
  width: 75%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.explore-page-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.52);
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.explore-page-container07 {
  flex: 0 0 auto;
  width: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-radius: 35px;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.explore-page-icon {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.explore-page-textinput {
  color: #8a8a8a;
  width: 450px;
  height: 50px;
  font-size: 24px;
  transition: 0.3s;
  border-width: 0px;
  padding-left: 0px;
  padding-bottom: 7px;
  background-color: var(--dl-color-gray-900);
}
.explore-page-textinput:focus {
  outline: none;
}
.explore-page-textinput:hover {
  width: 515;
}
.explore-page-link {
  display: contents;
}
.explore-page-icon2 {
  fill: var(--dl-color-primary-100);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.explore-page-button {
  display: none;
}
.explore-page-icon4 {
  width: 40px;
  height: 40px;
}
.explore-page-container08 {
  flex: 0 0 auto;
  max-width: 500px;
  display: flex;
  overflow: auto;
  align-items: center;
  border-radius: 35px;
  justify-content: flex-start;
}
.explore-page-sort-or-filter-indicator {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-primary-700);
  border-width: 1px;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.explore-page-sort-or-filter-indicator:hover {
    padding: 2px;
    border-color: red;
    transition: 0.3s;

  }

.explore-page-text01:hover {
    color: red;
    transition: 0.3s;
}

.react-select__value-container {
    display: flex !important;
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
    overflow-y: auto !important;
    /* max-height: 40px !important; */
  }
  
  .react-select__multi-value {
    min-width: auto !important;
  }

.explore-page-text {
  color: var(--dl-color-primary-500);
  font-size: 24px;
  font-family: Poppins;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
.explore-page-text01 {
  color: var(--dl-color-primary-500);
  font-size: 22px;
  font-family: Poppins;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
.explore-page-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  min-height: 80vh;
  margin: var(--dl-space-space-unit);
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: center;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.48);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgb(250, 250, 250)
}
.explore-page-cards-container {
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  flex-wrap: wrap;
  max-width: 1000px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.explore-page-text02 {
  width: 100%;
  align-self: flex-start;
  font-style: normal;
}
.explore-page-container10 {
  flex: 0 0 auto;
  width: 375px;
  height: 75vh;
  max-height: 1050px;
  display: flex;
  overflow: auto;
  position: fixed;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.explore-page-container-freeze {
    flex: 0 0 auto;
    width: 375px;
    height: 75vh;
    display: flex;
    overflow: auto;
    position: relative;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    align-self: flex-end;
    justify-self: flex-end;
    max-height: 1050px;
    margin-bottom: var(--dl-space-space-doubleunit);
  }

.explore-page-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.explore-page-text05 {
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-size: 1.6rem;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.55;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 1em;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.explore-page-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.explore-page-container13 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.explore-page-general-attributes {
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.explore-page-radiobutton {
  width: 1.5rem;
  height: 1.5rem;
}
.explore-page-select {
  width: 80%;
  height: 40px;
  font-size: larger;
  align-self: center;
  margin-bottom: var(--dl-space-space-unit);
}
.explore-page-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.explore-page-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.explore-page-general-attributes1 {
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.explore-page-radiobutton1 {
  width: 1.5rem;
  height: 1.5rem;
}
.explore-page-select1 {
  width: 80%;
  height: 40px;
  font-size: larger;
  align-self: center;
}
.explore-page-text06 {
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-size: 1.6rem;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.55;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 1em;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.explore-page-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.explore-page-container17 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.explore-page-general-attributes2 {
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.explore-page-checkbox {
  width: 1.5rem;
  height: 1.5rem;
}
.explore-page-textinput1 {
  width: 80%;
  align-self: center;
}
.explore-page-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.explore-page-container19 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.explore-page-general-attributes3 {
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.explore-page-text08 {
  font-style: normal;
  font-weight: 700;
}
.explore-page-checkbox1 {
  width: 1.5rem;
  height: 1.5rem;
}
.explore-page-rating {
  align-self: center;
}
.explore-page-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.explore-page-container21 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.explore-page-general-attributes4 {
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.explore-page-text10 {
  font-style: normal;
  font-weight: 700;
}
.explore-page-checkbox2 {
  width: 1.5rem;
  height: 1.5rem;
}
.explore-page-slider {
  width: 274px;
  align-self: center;
  align-items: center;
}
.explore-page-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.explore-page-container23 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.explore-page-general-attributes5 {
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.explore-page-text12 {
  font-style: normal;
  font-weight: 700;
}
.explore-page-checkbox3 {
  width: 1.5rem;
  height: 1.5rem;
}

.explore-page-container-x {
    display: none;
}

.filter-button {
    display: none;
}

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    height: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 3px;
  }

@media(max-width: 1200px) {

    .hide {
        display: none;
    }

    .filter-button {
        width: auto;
        align-self: center;
        display: flex;
        padding: 5px;
    }
    .explore-page-text01 {
        font-size: 14px;
    }

    .explore-page-container07 {
        border-radius: 10px;
        width: 100%;
        justify-content: space-between;
      }

    .explore-page-container07 {
        margin: 0px;
        width: 100%;
    }

  .explore-page-container04 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 999;
    height: 100vh;
  }
  .explore-page-container-x {
    display: flex;
    position: absolute;
    position: fixed;
    top: 0px;
    height: 40px;
    width: 75px;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 240, 240);
    border-radius: 10px;
    margin: 10px;
  }
  .explore-page-container08 {
    max-width: 100%;
    overflow: auto;
  }

  .explore-page-container05 {
    width: 100%;
  }
  .explore-page-button {
    display: flex;
    border-color: var(--dl-color-gray-white);
    flex-direction: row;
  }
  .explore-page-container10 {
    width: 100%;
    height: 100%;
    margin: 0px;
  }
  .explore-page-container11 {
    min-height: 100%;
  }
  .explore-page-container-freeze {
    width: 100%;
    height: 100%;
  }

  .explore-page-search-and-filter {
    width: 100%;
    flex-direction: row;
    display: flex;
  }
  .explore-page-container06 {
    flex-wrap: wrap;
  }
}
@media(max-width: 991px) {
  .explore-page-main {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }

  .explore-page-cards-container {
    justify-content: center;
  }
  .explore-page-cards-container {
    margin: 0px;
  }
}
@media(max-width: 767px) {
  .explore-page-main {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .explore-page-container05 {
    padding: var(--dl-space-space-halfunit);
  }
  .explore-page-container09 {
    padding: 0px;
  }
  .explore-page-text02 {
    margin-left: var(--dl-space-space-doubleunit);
  }

  .explore-page-textinput {
    width: 100%;
    height: 34px;
    font-size: 16px;
  }

  .form {
    width: 100%;
  }

  .explore-page-icon {
    width: 20px;
    height: 20px;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 5px;
  }
  .explore-page-icon2 {
    width: 20px;
    height: 20px;
    margin-right: var(--dl-space-space-halfunit);
  }

  .explore-page-text02 {
    font-size: 1.5rem;
    text-align: center;
    margin-left: 0px;
  }

  .explore-page-button {
    display: none;
  }

}

