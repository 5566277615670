.solid-button-container {
  display: flex;
  position: relative;
}
.solid-button-button {
  color: var(--dl-color-gray-white);
  border: none;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 50px;
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
  background-color: var(--dl-color-primary-mainblue);
}
.solid-button-root-class-name1 {
  margin-top: var(--dl-space-space-doubleunit);
}

@media(max-width: 479px) {
    .solid-button-button {
        color: var(--dl-color-gray-white);
        border: none;
        font-size: 14px;
        font-family: Poppins;
        font-weight: 500;
        padding-top: var(--dl-space-space-halfunit);
        padding-left: var(--dl-space-space-unit);
        border-radius: 50px;
        padding-right: var(--dl-space-space-unit);
        padding-bottom: var(--dl-space-space-halfunit);
        text-transform: none;
        text-decoration: none;
        background-color: var(--dl-color-primary-mainblue);
      }
      .solid-button-root-class-name1 {
        margin-top: var(--dl-space-space-unit);
      }
      
  }