.main-main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
  background-image: linear-gradient(90deg, rgb(254, 220, 255) 0.00%,rgb(170, 218, 219) 100.00%);
}
.main-cards-container {
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  overflow: hidden;
  flex-wrap: wrap;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.main-text {
  width: 100%;
  align-self: flex-start;
  font-style: normal;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--dl-color-primary-mainblue);
    border-radius: 7px;
  }

@media(max-width: 991px) {
  .main-cards-container {
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .main-main {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  /* width */
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 3px;
    visibility:hidden;
  }

  .scroller:hover::-webkit-scrollbar-thumb {
     visibility:visible;
  }
  .scroller:active::-webkit-scrollbar-thumb {
    visibility:visible;
 }
 .scroller:focus::-webkit-scrollbar-thumb {
    visibility:visible;
 }
}
@media(max-width: 479px) {
  .main-cards-container {
    height: 975px;
  }
  .main-text {
    font-size: 36px;
  }
}
