.hero-hero {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 80vh;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-quadruple);
  padding-right: var(--dl-space-space-quadruple);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-size: cover;
  justify-content: flex-start;
  background-image: url("../../public/playground_assets/imageedit_3_5598652371-1500w.jpg");
}
.hero-container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  max-width: 100%;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.hero-text {
  align-self: flex-start;
}
.hero-text02 {
  color: var(--dl-color-primary-mainblue);
}
.hero-text04 {
  font-size: 1.15rem;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  margin-bottom: var(--dl-space-space-quadruple);
}
.hero-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 90vw;
  align-self: flex-start;
  max-height: 100vh;
  overflow-y: auto;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-primary-100);
}
.hero-image {
  width: 1226px;
  max-width: 100%;
  object-fit: cover;
  margin-right: 2px;
}
.hero-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: flex-start;
  justify-content: space-between;
}
.hero-container3 {
  flex: 0 0 auto;
  width: 350px;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.hero-text05 {
  color: var(--dl-color-primary-mainblue);
  margin: var(--dl-space-space-unit);
  align-self: center;
}
.hero-text06 {
  font-size: 1.15rem;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.hero-container4 {
  flex: 0 0 auto;
  width: 350px;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.hero-text07 {
  color: var(--dl-color-primary-700);
  margin: var(--dl-space-space-unit);
  align-self: center;
}
.hero-text08 {
  font-size: 1.15rem;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.hero-container5 {
  flex: 0 0 auto;
  width: 350px;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.hero-text09 {
  color: var(--dl-color-gray-black);
  margin: var(--dl-space-space-unit);
  align-self: center;
}
.hero-text10 {
  width: auto;
  font-size: 1.15rem;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 1200px) {
  .hero-container {
    margin: var(--dl-space-space-doubleunit);
  }
  .hero-container1 {
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .hero-image {
    width: 972px;
  }
  .hero-container3 {
    width: 300px;
    margin: 0px;
  }
  .hero-text05 {
    margin: 0px;
    text-align: center;
  }
  .hero-container4 {
    width: 300px;
    margin: 0px;
  }
  .hero-text07 {
    margin: 0px;
  }
  .hero-container5 {
    width: 300px;
    margin: 0px;
  }
  .hero-text09 {
    margin: 0px;
  }
}
@media(max-width: 991px) {
  .hero-hero {
    flex-direction: column;
  }
  .hero-container {
    margin: var(--dl-space-space-doubleunit);
  }
  .hero-image {
    width: 750px;
  }
  .hero-container3 {
    width: 250px;
    margin: 0px;
  }
  .hero-text05 {
    text-align: center;
  }
  .hero-container4 {
    width: 250px;
    margin: 0px;
  }
  .hero-container5 {
    width: 250px;
    margin: 0px;
  }
}
@media(max-width: 767px) {
  .hero-hero {
    padding-left: 32px;
    padding-right: 32px;
  }
  .hero-text04 {
    text-align: center;
  }
  .hero-container3 {
    width: 200px;
  }
  .hero-text05 {
    font-size: 20px;
  }
  .hero-text06 {
    font-size: 12px;
    text-align: center;
  }
  .hero-container4 {
    width: 200px;
  }
  .hero-text07 {
    font-size: 20px;
  }
  .hero-text08 {
    font-size: 12px;
    text-align: center;
  }
  .hero-container5 {
    width: 200px;
  }
  .hero-text09 {
    font-size: 20px;
  }
  .hero-text10 {
    font-size: 12px;
    text-align: center;
  }
}
@media(max-width: 479px) {
  .hero-hero {
    width: 100%;
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .hero-container2 {
    align-items: center;
    flex-direction: column;
  }
  .hero-container3 {
    width: 250px;
  }
  .hero-text06 {
    margin: var(--dl-space-space-unit);
    font-size: 14px;
  }
  .hero-container4 {
    width: 250px;
  }
  .hero-text07 {
    margin: 0px;
    font-size: 20px;
  }
  .hero-text08 {
    margin: var(--dl-space-space-unit);
    font-size: 14px;
  }
  .hero-container5 {
    width: 250px;
  }
  .hero-text09 {
    margin: 0px;
    font-size: 20px;
  }
  .hero-text10 {
    font-size: 14px;
    margin-bottom: var(--dl-space-space-unit);
  }
}
