.modal-add-to-trip-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: none;
    padding: 0px;
    align-items: flex-start;
    flex-direction: column;
}
.modal-add-to-trip-container1 {
  flex: 0 0 auto;
  width: 657px;
  height: 700px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: center;
  align-items: flex-start;
  border-radius: 24px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.modal-add-to-trip-button {
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  padding: 0px;
  align-self: flex-start;
  background: none;
  border-width: 0;
  flex-direction: row;
}
.modal-add-to-trip-icon {
  width: 34px;
  height: 34px;
}
.modal-add-to-trip-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-left: var(--dl-space-space-doubleunit);
  margin-right: var(--dl-space-space-quadruple);
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.modal-add-to-trip-text {
  align-self: center;
}
.modal-add-to-trip-icon02 {
  fill: var(--dl-color-primary-500);
  width: 40px;
  height: 40px;
}
.modal-add-to-trip-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: center;
  align-items: center;
  padding-right: 2em;
  justify-content: space-between;
}
.modal-add-to-trip-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.modal-add-to-trip-button1 {
  height: 60px;
  width: 60px;
  align-self: flex-end;
  border-width: 0;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-primary-mainblue);
  justify-content: center;
  align-content: center;
  padding: 0px;
}
.modal-add-to-trip-icon04 {
  margin: 0px;
  margin-top: 5px;
  fill: var(--dl-color-gray-white);
  width: 34px;
  height: 34px;
}
.modal-add-to-trip-text1 {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.modal-add-to-trip-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-right: 2em;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
}
.modal-add-to-trip-container6 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.modal-add-to-trip-icon06 {
  width: 34px;
  height: 34px;
}
.modal-add-to-trip-text2 {
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.modal-add-to-trip-checkbox {
  width: 34px;
  height: 34px;
  align-self: center;
}

.modal-add-to-trip-icon08 {
  width: 34px;
  height: 34px;
}
.modal-add-to-trip-text3 {
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.modal-add-to-trip-checkbox1 {
  width: 34px;
  height: 34px;
  align-self: center;
}
.modal-add-to-trip-button2 {
  color: white;
  font-size: 24px;
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  margin-left: var(--dl-space-space-doubleunit);
  border-width: 0;
  margin-right: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 24px;
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-mainblue);
}

.modal-add-to-trip-trip-containers {
    height: 300px;
    width: 100%;
    overflow: auto;
}

@media(max-width: 767px) {
    .modal-add-to-trip-container1 {
      width: 95vw;
      max-height: 400px;
      height: auto;
      padding: var(--dl-space-space-halfunit);
    }
    .modal-add-to-trip-button {
      margin: var(--dl-space-space-halfunit);
    }
    .modal-add-to-trip-container2 {
      margin-left: var(--dl-space-space-halfunit);
    }
    .modal-add-to-trip-text {
      font-size: 24px;
    }
    .modal-add-to-trip-icon02 {
      width: 25px;
      height: 25px;
    }
    .modal-add-to-trip-container3 {
      padding: var(--dl-space-space-halfunit);
    }
    .modal-add-to-trip-button1 {
      width: 34px;
      height: 34px;
      padding: 0px;
      position: relative;
      align-self: center;
    }
    .modal-add-to-trip-icon04 {
      top: 0px;
      left: 0px;
      right: 0px;
      width: 25px;
      bottom: 0px;
      height: 25px;
      margin: auto;
      position: absolute;
    }
    .modal-add-to-trip-text1 {
      font-size: 16px;
    }
    .modal-add-to-trip-container5 {
      padding: var(--dl-space-space-halfunit);
    }
    .modal-add-to-trip-text2 {
      font-size: 14px;
    }
    .modal-add-to-trip-container7 {
      padding: var(--dl-space-space-halfunit);
    }
    .modal-add-to-trip-text3 {
      font-size: 14px;
    }
    .modal-add-to-trip-button2 {
            width: 100px;
            margin: var(--dl-space-space-halfunit);
            font-size: 16px;
            text-align: center;
            padding-left: var(--dl-space-space-halfunit);
            padding-right: var(--dl-space-space-halfunit);
    }
    .modal-add-to-trip-trip-containers {
        height: auto;
        max-height: 300px;
    }
  }