.mountain-card-visited-container {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.mountain-card-visited-container01 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}

.mountain-card-visited-container02 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  transition: 0.3s;
}

.mountain-card-visited-container02:hover {
    width: 310px;
    transition: 0.3s;
  }

.mountain-card-visited-container03 {
  width: 100%;
  height: 190px;
  display: flex;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
}
.mountain-card-visited-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 25%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  justify-content: flex-end;
}
.mountain-card-visited-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.mountain-card-visited-container05 {
  width: 100%;
  height: 70%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: flex-end;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: #ffffff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-radius8);
  border-bottom-right-radius: var(--dl-radius-radius-radius8);
}
.mountain-card-visited-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}
.mountain-card-visited-text {
  color: var(--dl-color-gray-500);
  font-size: 16px;
  max-width: 250px;
  font-style: normal;
  font-weight: 600;
}
.mountain-card-visited-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}
.mountain-card-visited-icon07 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-halfunit);
}
.mountain-card-visited-text01 {
  font-size: 16px;
  max-width: 250px;
}
.mountain-card-visited-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}
.mountain-card-visited-text02 {
  color: var(--dl-color-gray-500);
  font-size: 16px;
  max-width: 250px;
  font-style: normal;
  font-weight: 600;
}
.mountain-card-visited-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}
.mountain-card-visited-text03 {
  color: var(--dl-color-gray-500);
  font-size: 16px;
  max-width: 250px;
  font-style: normal;
  font-weight: 600;
}
.mountain-card-visited-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}
.mountain-card-visited-text04 {
  color: var(--dl-color-gray-500);
  font-size: 16px;
  max-width: 250px;
  font-style: normal;
  font-weight: 600;
}
.mountain-card-visited-icon09 {
  fill: #01b508;
  width: 24px;
  height: 24px;
  font-size: 16px;
}
.mountain-card-visited-text05 {
  font-size: 16px;
  max-width: 250px;
  font-style: normal;
  font-weight: 400;
}
.mountain-card-visited-icon11 {
  fill: #0600ff;
  width: 24px;
  height: 24px;
  font-size: 16px;
}
.mountain-card-visited-text06 {
  font-size: 16px;
  max-width: 250px;
  font-style: normal;
  font-weight: 400;
}
.mountain-card-visited-icon13 {
  width: 24px;
  height: 24px;
  font-size: 16px;
}
.mountain-card-visited-text07 {
  font-size: 16px;
  max-width: 250px;
}
.mountain-card-visited-container11 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.mountain-card-visited-container12 {
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.mountain-card-visited-text08 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 0px;
}
.mountain-card-visited-text09 {
  font-size: 16px;
  max-width: 250px;
}
.mountain-card-visited-container13 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.mountain-card-visited-container14 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.mountain-card-visited-text10 {
  font-size: 16px;
  max-width: auto;
}
.mountain-card-visited-container15 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.mountain-card-visited-icon15 {
  fill: #ffb400;
  width: 25px;
  height: 25px;
}
.mountain-card-visited-text11 {
  font-size: 16px;
  max-width: auto;
  align-self: center;
}
.mountain-card-visited-container16 {
  flex: 0 0 auto;
  width: 200px;
  height: 275px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: #effcff;
}
.mountain-card-visited-text12 {
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.mountain-card-visited-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.mountain-card-visited-container18 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.mountain-card-visited-icon17 {
  fill: #ffb400;
  width: 25px;
  height: 25px;
}
.mountain-card-visited-text14 {
  font-size: 16px;
  max-width: auto;
  align-self: center;
}
.mountain-card-visited-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.mountain-card-visited-container20 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.mountain-card-visited-icon19 {
  fill: #ffb400;
  width: 25px;
  height: 25px;
}
.mountain-card-visited-text16 {
  font-size: 16px;
  max-width: auto;
  align-self: center;
}
.mountain-card-visited-container21 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.mountain-card-visited-container22 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.mountain-card-visited-icon21 {
  fill: #ffb400;
  width: 25px;
  height: 25px;
}
.mountain-card-visited-text18 {
  font-size: 16px;
  max-width: auto;
  align-self: center;
}
.mountain-card-visited-icon23 {
  width: 34px;
  height: 34px;
  margin: var(--dl-space-space-halfunit);
  cursor: pointer;
  transition: 0.3s;
}

.mountain-card-visited-icon23:hover {
    width: 40px;
    height: 40px;
    margin: var(--dl-space-space-halfunit);
    cursor: pointer;
    transition: 0.3s;
  }

.mountain-card-visited-rating-box {
    max-height: 200px;
    overflow-y: auto;
}

@media(max-width: 991px) {
  .mountain-card-visited-text10 {
    font-size: 16px;
  }
}
@media(max-width: 767px) {
  .mountain-card-visited-container16 {
    display: none;
  }
}
