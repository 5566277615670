.review-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  border-bottom-color: lightgray;
  border-bottom-width: 1px;
  padding-bottom: var(--dl-space-space-unit);
}

.select-dropdown {
    width: 25%
}

.empty-reviews-text{
    color: gray;
    font-weight: normal;
}

.review-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.review-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.review-image {
  width: 25px;
  object-fit: cover;
}
.review-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.review-icon {
  width: 15px;
  height: 15px;
}
.review-icon02 {
  width: 18px;
  height: 18px;
}
.review-icon04 {
  width: 15px;
  height: 15px;
}
.review-text {
  font-size: 20px;
  margin-left: var(--dl-space-space-halfunit);
}
.review-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.review-icon06 {
  fill: #ffb400;
  width: 25px;
  height: 25px;
}
.review-icon08 {
  fill: #ffb400;
  width: 25px;
  height: 25px;
}
.review-icon10 {
  fill: #ffb400;
  width: 25px;
  height: 25px;
}
.review-icon12 {
  fill: #ffb400;
  width: 25px;
  height: 25px;
}
.review-icon14 {
  fill: #ffb400;
  width: 25px;
  height: 25px;
}
.review-text1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-left: var(--dl-space-space-unit);
}
.review-container5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.review-text2 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
