.mountain-card-container {
  width: 300px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
}
.mountain-card-container:hover {
  width: 310px;
}
.mountain-card-container01 {
  width: 100%;
  height: 190px;
  display: flex;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-color: #b6f0f1;
}
.mountain-card-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: 25%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  justify-content: flex-end;
}
.mountain-card-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.mountain-card-icon04 {
  width: 34px;
  height: 30px;
}

.mountain-card-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}
.mountain-card-text {
  color: var(--dl-color-gray-500);
  font-size: 16px;
  max-width: 250px;
  font-style: normal;
  font-weight: 600;
}
.mountain-card-container05 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}
.mountain-card-icon07 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-halfunit);
}
.mountain-card-text01 {
  font-size: 16px;
  max-width: 250px;
}
.mountain-card-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}
.mountain-card-text02 {
  color: var(--dl-color-gray-500);
  font-size: 16px;
  max-width: 250px;
  font-style: normal;
  font-weight: 600;
}
.mountain-card-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}
.mountain-card-text03 {
  color: var(--dl-color-gray-500);
  font-size: 16px;
  max-width: 250px;
  font-style: normal;
  font-weight: 600;
}
.mountain-card-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}

.mountain-card-container03 {
    /* width: 100%;
    height: 70%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-self: flex-end;
    align-items: center;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    background-color: #ffffff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--dl-radius-radius-radius8);
    border-bottom-right-radius: var(--dl-radius-radius-radius8); */
  }

  .mountain-enter {
    opacity: 0;
    height: 0;
    overflow: hidden;
    /* background-color: #01b508; */
    /* transition: opacity 1s, transform 0.5s, width 0.5s, height 0.5s, padding 0.5s, border-radius 0.5s; */
  }

  .mountain-enter-active {
    width: 100%;
    height: 70%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-self: flex-end;
    align-items: center;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    background-color: #ffffff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--dl-radius-radius-radius8);
    border-bottom-right-radius: var(--dl-radius-radius-radius8);
    opacity: 1;
    transition: 0.5s;
  }
  
  .mountain-enter-done {
    width: 100%;
    height: 70%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-self: flex-end;
    align-items: center;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    background-color: #ffffff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--dl-radius-radius-radius8);
    border-bottom-right-radius: var(--dl-radius-radius-radius8);
    opacity: 1;
  }

.mountain-exit {
    opacity: 1;
    background-color: #ffffff;
    height: 70%;
    width: 100%;
}

.mountain-exit-active {
    opacity: 0;
    overflow: hidden;
    height: 0;
    transition: 0.5s;
}

  /* .mountain-enter-done {
    width: 100%;
    height: 70%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-self: flex-end;
    align-items: center;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    background-color: #ffffff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--dl-radius-radius-radius8);
    border-bottom-right-radius: var(--dl-radius-radius-radius8);

    transition: 3s;
  } */
  

.mountain-card-text04 {
  color: var(--dl-color-gray-500);
  font-size: 16px;
  max-width: 250px;
  font-style: normal;
  font-weight: 600;
}
.mountain-card-icon09 {
  fill: #01b508;
  width: 24px;
  height: 24px;
  font-size: 16px;
}
.mountain-card-text05 {
  font-size: 16px;
  max-width: 250px;
  font-style: normal;
  font-weight: 400;
}
.mountain-card-icon11 {
  fill: #0600ff;
  width: 24px;
  height: 24px;
  font-size: 16px;
}

.icon {
    width: 24px;
    height: 24px;
  }

.mountain-card-text06 {
  font-size: 16px;
  max-width: 250px;
  font-style: normal;
  font-weight: 400;
}
.mountain-card-icon13 {
  width: 24px;
  height: 24px;
  font-size: 16px;
}
.mountain-card-text07 {
  font-size: 16px;
  max-width: 250px;
}
.mountain-card-container09 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.mountain-card-container10 {
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.mountain-card-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 0px;
}
.mountain-card-text09 {
  font-size: 12px;
  max-width: 250px;
}
.mountain-card-container11 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.mountain-card-container12 {
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.mountain-card-icon15 {
  fill: #ffb400;
  width: 20px;
  height: 20px;
}
.mountain-card-text10 {
  font-size: 14px;
  max-width: auto;
  align-self: center;
}
.mountain-card-container13 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.mountain-card-icon17 {
  fill: #00faff;
  width: 20px;
  height: 20px;
}
.mountain-card-text11 {
  font-size: 14px;
  max-width: auto;
  align-self: center;
  margin-left: 10px;
  font-weight: 600;
  color:crimson;
}
.mountain-card-text12 {
    font-size: 14px;
    max-width: auto;
    align-self: center;
    margin-left: 5px;
    font-weight: 300;
    color: black;
  }
@media(max-width: 767px) {
  .mountain-card-container {
    width: 300px;
  }
}
@media(max-width: 479px) {
  .mountain-card-container {
    width: 300px;
  }
  .mountain-card-text {
    font-size: 12;
  }
  .mountain-card-text01 {
    font-size: 12px;
  }
  .mountain-card-text02 {
    font-size: 12;
  }
  .mountain-card-text03 {
    font-size: 12;
  }
  .mountain-card-text04 {
    font-size: 12px;
  }
  .mountain-card-icon09 {
    font-size: 12px;
  }
  .mountain-card-text05 {
    font-size: 12px;
  }
  .mountain-card-icon11 {
    font-size: 12px;
  }
  .mountain-card-text06 {
    font-size: 12px;
  }
  .mountain-card-icon13 {
    font-size: 12px;
  }
  .mountain-card-text07 {
    font-size: 12px;
  }
}
