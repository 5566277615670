.dash-full-size-map-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: black;
    padding: 0px;
    align-items: flex-start;
    flex-direction: column;
}

.dash-full-size-map-modal-div {
    width: 95vw;
    height: 90vh;
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.dash-full-size-map-modal-img {
    width: 90vw;
    height: auto;
}

.dash-full-size-map-modal-x {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 40px;
    width: 75px;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 240, 240);
    border-radius: 10px;
    margin: 10px;
    justify-self: flex-start;
    cursor: pointer;
  }

@media(max-width: 1200px){
    .dash-full-size-map-modal-div {
        width: 95vw;
        height: 90vh;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    .dash-full-size-map-modal-img {
        width: auto;
        height: 85vh;
    }
}