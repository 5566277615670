.trips-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.trips-page-top-container {
  width: 100%;
  height: auto;
  min-height: 75vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgb(12, 77, 130) 0.00%,rgb(203, 254, 255) 100.00%);
}
.trips-page-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: fixed;
  align-items: flex-start;
}
.trips-page-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
}
.trips-page-main {
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1500px;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.trips-page-container03 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  justify-content: center;
}
.trips-page-container04 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
}
.trips-page-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.trips-page-container06 {
  flex: 0 0 auto;
  width: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-radius: 35px;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.trips-page-icon {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.trips-page-textinput {
  color: #8a8a8a;
  width: 450px;
  height: 50px;
  font-size: 24px;
  transition: 0.3s;
  border-width: 0px;
  padding-left: 0px;
  padding-bottom: 7px;
  background-color: var(--dl-color-gray-900);
}
.trips-page-textinput:focus {
  outline: none;
}
.trips-page-textinput:hover {
  width: 515;
}
.trips-page-link {
  display: contents;
}
.trips-page-icon02 {
  fill: var(--dl-color-primary-100);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.trips-page-cards-container {
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  flex-wrap: wrap;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.trips-page-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}
.trips-page-icon04 {
  fill: var(--dl-color-primary-700);
  width: 50px;
  height: 50px;
  margin-right: var(--dl-space-space-unit);
  transition: 0.3s;
}

.trips-page-icon04:hover {
    width: 60px;
    height: 60px;
    transition: 0.3s;
}
.trips-page-text {
  color: var(--dl-color-primary-700);
}
.trips-page-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: center;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  min-height: 500px;
}
.trips-page-cards-container1 {
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  flex-wrap: wrap;
  max-width: 1000px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.trips-page-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.trips-page-container10 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.trips-page-text01 {
  color: var(--dl-color-gray-500);
  width: auto;
  align-self: flex-start;
  font-style: normal;
}
.trips-page-icon06 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
}
.trips-page-container11 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.trips-page-container12 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.trips-page-text04 {
  margin-right: var(--dl-space-space-unit);
}
.trips-page-text06 {
  margin-left: var(--dl-space-space-unit);
  margin-right: 0px;
}
.trips-page-icon08 {
  width: 24px;
  height: 20px;
}
.trips-page-container13 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.trips-page-icon10 {
  fill: var(--dl-color-primary-mainblue);
  width: 75px;
  height: 75px;
}
.trips-page-cards-container2 {
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  flex-wrap: wrap;
  max-width: 1000px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.trips-page-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.trips-page-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.trips-page-text08 {
  color: var(--dl-color-gray-500);
  width: auto;
  align-self: flex-start;
  font-style: normal;
}
.trips-page-icon12 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
}
.trips-page-container16 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.trips-page-container17 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.trips-page-text11 {
  margin-right: var(--dl-space-space-unit);
}
.trips-page-text13 {
  margin-left: var(--dl-space-space-unit);
  margin-right: 0px;
}
.trips-page-icon14 {
  width: 24px;
  height: 20px;
}
.trips-page-container18 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.trips-page-icon16 {
  fill: var(--dl-color-primary-mainblue);
  width: 75px;
  height: 75px;
}
.trips-page-placeholder-text {
    font-size: 44px;
    color: var(--dl-color-gray-500);
    align-self: center;
    margin: var(--dl-space-space-unit);
  }

@media(max-width: 1200px) {
  .trips-page-container04 {
    width: 100%;
  }
}
@media(max-width: 991px) {
  .trips-page-placeholder-text {
    font-size: 24px;
    color: var(--dl-color-gray-500);
    align-self: center;
    margin: var(--dl-space-space-unit);
  }
  .trips-page-main {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .trips-page-container05 {
    flex-wrap: wrap;
  }
  .trips-page-cards-container {
    justify-content: center;
  }
  .trips-page-cards-container1 {
    justify-content: center;
  }
  .trips-page-cards-container2 {
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .trips-page-main {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .trips-page-container04 {
    padding: var(--dl-space-space-halfunit);
  }
  .trips-page-container08 {
    padding: 0px;
  }
  .trips-page-text01 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .trips-page-text08 {
    margin-left: var(--dl-space-space-doubleunit);
  }

  .trips-page-textinput {
    width: 100%;
    height: 34px;
    font-size: 16px;
  }

  .form {
    width: 100%;
  }

  .trips-page-icon {
    width: 20px;
    height: 20px;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 5px;
  }
  .trips-page-icon2 {
    width: 20px;
    height: 20px;
    margin-right: var(--dl-space-space-halfunit);
  }
  .trips-page-container06 {
    margin: var(--dl-space-space-halfunit);
    border-radius: 10px;
    width: 100%;
    justify-content: space-between;
  }

  .trips-page-icon04 {
    height: 34px;
    width: 34px;
  }

  .trips-page-text {
    font-size: 16px;
  }
}
@media(max-width: 479px) {
  .trips-page-textinput {
    width: 300px;
    height: 34px;
    font-size: 16px;
  }
  .trips-page-text01 {
    font-size: 1.5rem;
    text-align: center;
    margin-left: 0px;
  }
  .trips-page-text08 {
    font-size: 1.5rem;
    text-align: center;
    margin-left: 0px;
  }
}
