.sign-up-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.sign-up-page-top-container {
  width: 100%;
  height: 964px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1515268064940-5150b7c29f35?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDY1fHxtb3VudGFpbiUyMHNub3d8ZW58MHx8fHwxNjY4NjQ0Mjcz&ixlib=rb-4.0.3&w=1500");
  background-position: 0% 40%;
}
.sign-up-page-hero {
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: center;
}
.sign-up-page-content-container {
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.48);
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  padding-bottom: 1em;
  background-color: var(--dl-color-gray-white);
}
.sign-up-page-text {
  color: var(--dl-color-primary-500);
  font-size: 32px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
.sign-up-page-button {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  border-width: 0px;
  border-radius: 16px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #4184f3;
}
.sign-up-page-image {
  width: 24px;
  height: auto;
  object-fit: cover;
}
.sign-up-page-text01 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
}
.sign-up-page-image1 {
  width: 24px;
  visibility: hidden;
}
.sign-up-page-button1 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  border-width: 0px;
  border-radius: 16px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #3C5997;
}
.sign-up-page-image2 {
  width: 24px;
  object-fit: cover;
}
.sign-up-page-text04 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
}
.sign-up-page-image3 {
  width: 24px;
  visibility: hidden;
}
.sign-up-page-button2 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  padding-top: 0.5rem;
  border-width: 0px;
  border-radius: 16px;
  flex-direction: row;
  padding-bottom: 0.5rem;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.sign-up-page-image4 {
  width: auto;
  height: 24px;
  object-fit: cover;
}
.sign-up-page-text07 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
}
.sign-up-page-image5 {
  width: 24px;
  visibility: hidden;
}
.sign-up-page-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  justify-content: center;
}
.sign-up-page-container2 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
}
.sign-up-page-text10 {
  font-size: 10px;
  margin-top: 0px;
  text-align: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
}
.sign-up-page-container3 {
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
}
.sign-up-page-textinput {
  width: 100%;
  height: 40px;
  margin-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
}
.sign-up-page-textinput1 {
  width: 100%;
  height: 40px;
  margin-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
}
.sign-up-page-textinput2 {
  width: 100%;
  height: 40px;
  margin-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
}
.sign-up-page-textinput3 {
  width: 100%;
  height: 40px;
  margin-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
}
.sign-up-page-button3 {
  color: var(--dl-color-gray-white);
  width: 100%;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  border-color: var(--dl-color-primary-mainblue);
  border-radius: 16px;
  background-color: var(--dl-color-primary-mainblue);
}
.sign-up-page-text13 {
  font-weight: 600;
}
.sign-up-page-text16 {
  margin-top: var(--dl-space-space-doubleunit);
}
.sign-up-page-text18 {
  color: var(--dl-color-primary-mainblue);
}
.sign-up-page-text19 {
  width: 100%;
  font-size: 12px;
}
.sign-up-page-scroll-up-button {
  top: 90vh;
  right: 10vh;
  width: 50px;
  height: 50px;
  display: flex;
  position: fixed;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-mainblue);
}
.sign-up-page-scroll-up-button:hover {
  width: 60px;
  height: 60px;
}
.sign-up-page-icon {
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .sign-up-page-hero {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 767px) {
  .sign-up-page-hero {
    justify-content: center;
  }
  .sign-up-page-content-container {
    width: 390px;
    height: 676px;
    align-items: center;
  }
  .sign-up-page-text {
    color: var(--dl-color-primary-500);
    font-size: 24px;
    font-family: Poppins;
    font-weight: 700;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }
  .sign-up-page-button {
    width: 100%;
  }
  .sign-up-page-button1 {
    width: 100%;
  }
  .sign-up-page-button2 {
    width: 354px;
  }
  .sign-up-page-text10 {
    text-align: center;
  }
  .sign-up-page-text11 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .sign-up-page-scroll-up-button {
    display: none;
  }
}
