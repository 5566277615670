.result-page-selector-container {
    width: auto;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-self: center;
  }
  .result-page-selector-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
  }
  .result-page-selector-container2 {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    cursor: pointer;
  }
  .result-page-selector-icon {
    fill: var(--dl-color-gray-500);
    width: 34px;
    height: 40px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .result-page-selector-current {
    flex: 0 0 auto;
    width: 34px;
    height: 34px;
    margin: var(--dl-space-space-halfunit);
    display: flex;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-primary-mainblue);
    cursor: pointer;
  }
  .result-page-selector-text {
    color: var(--dl-color-gray-500);
    font-size: 16px;
  }
  .result-page-selector-text-current {
    color: white;
    font-size: 16px;
  }
  .result-page-selector-number {
    flex: 0 0 auto;
    width: 34px;
    height: 34px;
    margin: var(--dl-space-space-halfunit);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }
  .result-page-selector-icon2 {
    fill: var(--dl-color-gray-500);
    width: 24px;
    height: 24px;
  }
  .result-page-selector-container7 {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    cursor: pointer;

  }
  .result-page-selector-icon4 {
    fill: var(--dl-color-gray-500);
    width: 34px;
    height: 34px;
    margin-left: var(--dl-space-space-doubleunit);
  }
  
  @media(max-width: 767px) {
    .result-page-selector-container2 {
        display: none;
    }
    .result-page-selector-container7 {
        display: none;
    }
  }