.nav-bar-public-header {
  width: 100%;
  display: flex;
  position: relative;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  align-items: center;
  padding-top: 15px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 15px;
  justify-content: space-between;
  background-color: #ffffff;
}
.nav-bar-public-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.nav-bar-public-text {
  font-size: 24px;
  font-family: Poppins;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.nav-bar-public-text1 {
  font-size: 28px;
  font-weight: 900;
}
.nav-bar-public-nav {
  display: flex;
}
.nav-bar-public-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.nav-bar-public-sign-up {
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  border-width: 0px;
  margin-right: var(--dl-space-space-unit);
  border-radius: 15px;
  background-color: var(--dl-color-primary-mainblue);
}
.nav-bar-public-log-in {
  font-style: normal;
  font-weight: 700;
  border-color: var(--dl-color-gray-900);
  border-width: 2px;
  border-radius: 15px;
}
.nav-bar-public-burger-menu {
  display: none;
}
.nav-bar-public-icon {
  width: 16px;
  height: 16px;
}
.nav-bar-public-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 32px;
  z-index: 100;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.nav-bar-public-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nav-bar-public-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  justify-content: space-between;
}
.nav-bar-public-image {
  height: 2rem;
}
.nav-bar-public-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-bar-public-icon02 {
  width: 16px;
  height: 16px;
}
.nav-bar-public-icon04 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.nav-bar-public-icon06 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.nav-bar-public-icon08 {
  width: 16px;
  height: 16px;
}
.nav-bar-public-root-class-name {
  top: 0px;
  left: 0px;
  position: absolute;
}
.nav-bar-public-root-class-name1 {
  top: 0px;
  left: 0px;
  position: absolute;
}
.nav-bar-public-root-class-name2 {
  top: 0px;
  left: 0px;
  position: absolute;
}
@media(max-width: 767px) {
  .nav-bar-public-header {
    padding-left: 32px;
    padding-right: 32px;
  }
  .nav-bar-public-nav {
    display: none;
  }
  .nav-bar-public-btn-group {
    display: none;
  }
  .nav-bar-public-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .nav-bar-public-header {
    padding: var(--dl-space-space-unit);
  }
  .nav-bar-public-mobile-menu {
    padding: 16px;
  }
}
