.dash-trail-and-stats-container {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-primary-lightblue);
}
.dash-trail-and-stats-container1 {
  flex: 0 0 auto;
  width: 500px;
  height: 300px;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-self: flex-start;
  transition: 0.3s;
  align-items: flex-end;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("../../public/playground_assets/pastedimage-fun-400h.png");
}
.dash-trail-and-stats-container1:hover {
  width: 510px;
  height: 306px;
}
.dash-trail-and-stats-icon {
  fill: var(--dl-color-gray-black);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.dash-trail-and-stats-text {
  color: var(--dl-color-primary-100);
  font-size: 20px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.dash-trail-and-stats-text01 {
  color: var(--dl-color-primary-500);
  font-size: 20px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.dash-trail-and-stats-text02 {
  font-weight: 400;
}
.dash-trail-and-stats-text03 {
  font-style: normal;
}
.dash-trail-and-stats-text04 {
  color: var(--dl-color-primary-500);
  font-size: 20px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.dash-trail-and-stats-text05 {
  font-weight: 400;
}
.dash-trail-and-stats-text06 {
  font-style: normal;
  font-weight: 400;
}
.dash-trail-and-stats-text07 {
  font-style: normal;
  font-weight: 400;
}
.dash-trail-and-stats-text08 {
  font-style: normal;
}
.dash-trail-and-stats-text09 {
  color: var(--dl-color-primary-500);
  font-size: 20px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: 0px;
  text-transform: none;
  text-decoration: none;
}
.dash-trail-and-stats-text10 {
  font-weight: 400;
}
.dash-trail-and-stats-text11 {
  font-style: normal;
}
.dash-trail-and-stats-text12 {
  color: var(--dl-color-primary-500);
  font-size: 20px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.dash-trail-and-stats-text13 {
  font-weight: 400;
}
.dash-trail-and-stats-text14 {
  font-style: normal;
}
@media(max-width: 1200px) {
  .dash-trail-and-stats-container1 {
    flex: 0 0 auto;
    width: 600px;
    max-width: 80vw;
    height: 300px;
    margin: var(--dl-space-space-unit);
    display: flex;
    align-self: flex-start;
    transition: 0.3s;
    align-items: flex-end;
    background-size: cover;
    justify-content: flex-end;
    background-image: url("../../public/playground_assets/pastedimage-fun-400h.png");
  }
  .dash-trail-and-stats-container1:hover {
    width: 510px;
    height: 306px;
  }
}
