.dash-local-mountain-report-container {
  width: auto;
  height: auto;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  position: relative;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: #ffffff;
}
.dash-local-mountain-report-text {
  margin: var(--dl-space-space-unit);
  font-size: 2rem;
  font-family: Poppins;
  font-weight: 700;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
}
.dash-local-mountain-report-container1 {
  width: auto;
  height: auto;
  display: flex;
  margin-top: 0px;
  align-items: flex-start;
  margin-left: var(--dl-space-space-doubleunit);
  margin-right: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.dash-local-mountain-report-text01 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.dash-local-mountain-report-text03 {
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-doubleunit);
  font-weight: 700;
}
.dash-local-mountain-report-text13 {
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-doubleunit);
  font-weight: 700;
}
.dash-local-mountain-report-text05 {
    font-size: 30px;
    font-style: normal;
    margin: var(--dl-space-space-doubleunit);
    margin-top: 0px;
    font-weight: 200;
    align-self: center;
  }
@media(max-width: 1200px) {
  .dash-local-mountain-report-text {
    margin: var(--dl-space-space-halfunit);
    align-self: center;
    font-size: 20px;
  }
  .dash-local-mountain-report-container1 {
    margin: var(--dl-space-space-unit);
  }
  .dash-local-mountain-report-text02 {
    font-size: 14px;
  }
  .dash-local-mountain-report-text04 {
    font-size: 14px;
  }
  .dash-local-mountain-report-text14 {
    font-size: 14px;
  }
  .dash-local-mountain-report-text05 {
    font-size: 20px;
    font-style: normal;
    margin: var(--dl-space-space-unit);
    margin-top: 0px;
    font-weight: 200;
    align-self: center;
  }
}
