.profile-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5vh;
    justify-content: center;
    height: 100vh;
    background-image: linear-gradient(#feac5e 0%, #c779d0 50%, #4bc0c8 100%);
    font-family: sans-serif;
  }
  
  .profile-picture-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-right: 50px;
  }
  
  .profile-picture {
    width: 100%;
  }
  
  .profile-details {
    display: flex;
    flex-direction: column;
    color: #333;
  }
  
  .profile-name {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #3f51b5;
  }
  
  .profile-info {
    font-size: 24px;
    margin-bottom: 5px;
    color: black;
  }
  
  .profile-divider {
    width: 100%;
    height: 1px;
    border: none;
    background-color: #ccc;
    margin: 20px 0;
  }
  
  .profile-bio {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .logout-button {
    background-color: #3f51b5;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    width: 400px;
    height: 40px;
    margin: var(--dl-space-space-halfunit);
  }
  
  .logout-button:hover {
    background-color: #1a237e;
  }
  .profile-setup-button {
    width: 400px;
    height: 40px;
    background-color: #2dafff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    padding: 10px 20px;
    font-weight: 400;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: background-color 0.3s ease-in-out;
    margin: var(--dl-space-space-halfunit);
  }
  
  .profile-setup-button:hover {
    background-color: #2d87c6;
  }
  
  @media (max-width: 768px) {
    .profile-container {
      flex-direction: column;
      justify-content: flex-start;
      padding: 50px;
    }
  
    .profile-picture-container {
      margin-right: 0;
      margin-bottom: 50px;
    }
  
    .profile-details {
      text-align: center;
    }
  }
  