.modal-rate-mountain-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: none;
    padding: 0px;
    align-items: flex-start;
    flex-direction: column;
}

.modal-rate-mountain-container1 {
    flex: 0 0 auto;
    width: 657px;
    height: 700px;
    display: flex;
    padding: var(--dl-space-space-doubleunit);
    align-self: center;
    align-items: flex-start;
    border-radius: 24px;
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.modal-rate-mountain-button {
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  padding: 0px;
  align-self: flex-start;
  background: none;
  border-width: 0;
  flex-direction: row;
}
.modal-rate-mountain-icon {
  width: 34px;
  height: 34px;
}
.modal-rate-mountain-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-left: var(--dl-space-space-doubleunit);
  margin-right: var(--dl-space-space-quadruple);
  margin-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.modal-rate-mountain-text {
  align-self: center;
}
.modal-rate-mountain-icon02 {
  fill: #ffbe00;
  width: 40px;
  height: 40px;
  margin-left: var(--dl-space-space-unit);
}
.modal-rate-mountain-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: 0px;
  align-items: center;
  margin-left: var(--dl-space-space-doubleunit);
  margin-right: var(--dl-space-space-doubleunit);
  margin-bottom: 0px;
  justify-content: space-between;
}
.modal-rate-mountain-button1 {
  font-size: 24px;
  align-self: flex-end;
  border-color: var(--dl-color-gray-white);
  padding-left: 0px;
  background-color: transparent;
}
.modal-rate-mountain-icon04 {
  width: var(--dl-size-size-tripleunit);
  height: var(--dl-size-size-tripleunit);
}
.modal-rate-mountain-button2 {
  font-size: 24px;
  align-self: flex-end;
  border-color: var(--dl-color-gray-white);
  background-color: transparent;
}
.modal-rate-mountain-icon06 {
  width: var(--dl-size-size-tripleunit);
  height: var(--dl-size-size-tripleunit);
}
.modal-rate-mountain-button3 {
  font-size: 24px;
  align-self: flex-end;
  border-color: var(--dl-color-gray-white);
  background-color: transparent;
}
.modal-rate-mountain-icon08 {
  width: var(--dl-size-size-tripleunit);
  height: var(--dl-size-size-tripleunit);
}
.modal-rate-mountain-button4 {
  font-size: 24px;
  align-self: flex-end;
  border-color: var(--dl-color-gray-white);
  background-color: transparent;
}
.modal-rate-mountain-icon10 {
  width: var(--dl-size-size-tripleunit);
  height: var(--dl-size-size-tripleunit);
}
.modal-rate-mountain-button5 {
  font-size: 24px;
  align-self: flex-end;
  border-color: var(--dl-color-gray-white);
  background-color: transparent;
}
.modal-rate-mountain-icon12 {
  width: var(--dl-size-size-tripleunit);
  height: var(--dl-size-size-tripleunit);
}
.modal-rate-mountain-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: center;
  align-items: flex-start;
  border-radius: 24px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.modal-rate-mountain-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: flex-start;
}
.modal-rate-mountain-icon14 {
  width: 34px;
  height: 34px;
}
.modal-rate-mountain-text1 {
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.modal-rate-mountain-container6 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  justify-content: flex-start;
}
.modal-rate-mountain-icon16 {
  width: 34px;
  height: 34px;
}
.modal-rate-mountain-text2 {
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.modal-rate-mountain-textarea {
  width: 100%;
  resize: none;
}
.modal-rate-mountain-button6 {
  color: white;
  font-size: 24px;
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  margin-left: var(--dl-space-space-doubleunit);
  border-width: 0;
  margin-right: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 24px;
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-mainblue);
}

@media(max-width: 767px) {
    .modal-rate-mountain-container1 {
      width: 95vw;
      height: auto;
      padding: var(--dl-space-space-unit);
    }
    .modal-rate-mountain-button {
      margin: var(--dl-space-space-halfunit);
    }
    .modal-rate-mountain-container2 {
      margin-left: var(--dl-space-space-halfunit);
    }
    .modal-rate-mountain-text {
      font-size: 24px;
    }
    .modal-rate-mountain-button6 {
        color: white;
        font-size: 16px;
        align-self: flex-end;
        border-width: 0;
        padding-left: var(--dl-space-space-doubleunit);
        border-radius: 24px;
        padding-right: var(--dl-space-space-doubleunit);
        background-color: var(--dl-color-primary-mainblue);
        margin: var(--dl-space-space-halfunit);
        margin-top: var(--dl-space-space-doubleunit);
      }
    
      .modal-rate-mountain-container4 {
        padding: 0px;
      }

      .modal-rate-mountain-container3 {
        margin: 0px;
      }

      .modal-rate-mountain-container6 {
        margin: 0px;
      }
  }