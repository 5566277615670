/* 
  
  #main-header {
    background: linear-gradient(to right, #1e3c72, #2a5298);
    color: white;
    padding: 40px 0;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  #main-header h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  #main-header p {
    font-size: 1.25em;
  } */
  
  #about {
    font-size: 1.1em;
    line-height: 1.6;
    color: #333;
    margin: 0 auto;
  }
  
  #about h2 {
    color: var(--dl-color-primary-mainblue);
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  #about h3 {
    color: var(--dl-color-primary-mainblue);
    font-size: 1.5em;
    margin-top: 30px;
  }
  
  #about p,
  #about ul {
    margin-bottom: 20px;
  }
  
  #about ul li {
    margin-bottom: 10px;
  }
  
  #about p strong {
    color: var(--dl-color-primary-mainblue);
  }
  
  