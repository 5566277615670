.landing-page-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.landing-page-top-container {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1550443640-6e18cdd503a4?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE2fHxza2l8ZW58MHx8fHwxNjY4Mjc0MjMw&ixlib=rb-4.0.3&w=1500");
  background-position: 0% 40%;
}
.landing-page-hero {
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: center;
}
.landing-page-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.landing-page-text {
  color: var(--dl-color-primary-500);
  margin-bottom: var(--dl-space-space-halfunit);
}
.landing-page-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 35px;
  margin-bottom: var(--dl-space-space-doubleunit);
  justify-content: center;
  background-color: #ffffff;
}
.landing-page-icon {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.landing-page-textinput {
  color: #8a8a8a;
  width: 501px;
  height: 69px;
  font-size: 24px;
  transition: 0.3s;
  border-width: 0px;
  padding-left: 0px;
  padding-bottom: 7px;
}
.landing-page-textinput:focus {
  outline: none;
}
.landing-page-textinput:hover {
  width: 515px;
}
.landing-page-link {
  display: contents;
}
.landing-page-icon2 {
  fill: var(--dl-color-primary-mainblue);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
@media(max-width: 991px) {
  .landing-page-hero {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 767px) {
  .landing-page-hero {
    justify-content: center;
  }
  .landing-page-content-container {
    align-items: center;
  }
  .landing-page-text {
    font-size: 2.5rem;
  }
}
@media(max-width: 479px) {
  .landing-page-text {
    font-size: 2rem;
    text-align: center;
  }
  .landing-page-textinput {
    width: 250px;
    font-size: 16px;
  }
  .landing-page-textinput:hover {
    width: 250px;
    font-size: 16px;
  }
  .landing-page-icon {
    fill: var(--dl-color-gray-500);
    width: 15px;
    height: 15px;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
  }
  .input {
    height: 40px
  }
}
