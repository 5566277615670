.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: none;
  }

.modal-create-trip-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    background-color: none;
    padding: 0px;
    align-items: flex-start;
    flex-direction: column;
    backdrop-filter: none;
    border-radius: 24px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
  .modal-create-trip-container1 {
    flex: 0 0 auto;
    width: 657px;
    height: 700px;
    display: flex;
    padding: var(--dl-space-space-doubleunit);
    align-self: center;
    align-items: flex-start;
    border-radius: 24px;
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
  }
  .modal-create-trip-button {
    margin: var(--dl-space-space-doubleunit);
    display: flex;
    padding: 0px;
    align-self: flex-start;
    background: none;
    border-width: 0;
    flex-direction: row;
  }
  .modal-create-trip-icon {
    width: 34px;
    height: 34px;
  }
  .modal-create-trip-container2 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-self: flex-start;
    margin-top: 0px;
    align-items: center;
    margin-left: var(--dl-space-space-doubleunit);
    margin-right: var(--dl-space-space-quadruple);
    margin-bottom: 0px;
    justify-content: space-between;
  }
  .modal-create-trip-text {
    align-self: center;
  }
  .modal-create-trip-icon2 {
    fill: var(--dl-color-primary-500);
    width: 40px;
    height: 40px;
    margin-left: var(--dl-space-space-unit);
  }
  .modal-create-trip-container3 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-doubleunit);
    align-self: center;
    align-items: flex-start;
    border-radius: 24px;
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
  }
  .modal-create-trip-container4 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: flex-start;
  }
  .modal-create-trip-text1 {
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }
  .modal-create-trip-textinput {
    width: 100%;
    height: 39px;
  }
  .modal-create-trip-container5 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-self: flex-start;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
  }
  .modal-create-trip-text2 {
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }
  .modal-create-trip-container6 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-self: flex-start;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
  }
  .modal-create-trip-text3 {
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }
  .modal-create-trip-textarea {
    width: 100%;
    resize: none;
  }
  .modal-create-trip-button1 {
    color: white;
    font-size: 24px;
    align-self: flex-end;
    margin-top: var(--dl-space-space-doubleunit);
    margin-left: var(--dl-space-space-doubleunit);
    border-width: 0;
    margin-right: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-doubleunit);
    border-radius: 24px;
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-doubleunit);
    background-color: var(--dl-color-primary-mainblue);
  }

  .modal-create-trip-date-picker {
    border-width: 1px;
    border-color: black;
    border-radius: 5px;
  }

  @media(max-width: 767px) {
    .modal-create-trip-container1 {
      width: 95vw;
      height: 400px;
      padding: var(--dl-space-space-halfunit);
    }
    .modal-create-trip-button {
      margin: var(--dl-space-space-halfunit);
    }
    .modal-create-trip-container2 {
      margin-left: var(--dl-space-space-halfunit);
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .modal-create-trip-text {
      font-size: 24px;
    }
    .modal-create-trip-icon2 {
      width: 30px;
      height: 30px;
    }
    .modal-create-trip-container3 {
      padding: var(--dl-space-space-halfunit);
    }
    .modal-create-trip-text1 {
      font-size: 14px;
    }
    .modal-create-trip-text2 {
      font-size: 14px;
    }
    .modal-create-trip-text3 {
      font-size: 14px;
    }
    .modal-create-trip-button1 {
      width: 100px;
      margin: var(--dl-space-space-halfunit);
      font-size: 16px;
      text-align: center;
      padding-left: var(--dl-space-space-halfunit);
      padding-right: var(--dl-space-space-halfunit);
    }
  }