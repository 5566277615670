.phone-poster-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #e6f3f4;
}
.phone-poster-pasted-image {
  max-width: 100%;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-quadruple);
}
.phone-poster-text {
  color: var(--dl-color-primary-mainblue);
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-doubleunit);
  text-transform: none;
  text-decoration: none;
}
.phone-poster-text2 {
  color: var(--dl-color-primary-500);
}
.phone-poster-text4 {
  color: var(--dl-color-primary-500);
}

@media(max-width: 479px){
    .phone-poster-text {
        font-size: 24px;
    }
}