ul {
    display: flex;
    padding: 0;
  }
  
  li {
    list-style-type: none;
    margin-right: 10px;
  }
  
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  a {
    text-decoration: none;
  }
  
  nav > button {
    background: transparent;
    color: var(--white);
    border: none;
  }
  