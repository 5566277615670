.modal-save-mountain-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: none;
  padding: 0px;
  align-items: flex-start;
  flex-direction: column;
}
.modal-save-mountain-container1 {
  flex: 0 0 auto;
  width: 657px;
  height: 600px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: center;
  align-items: flex-start;
  border-radius: 24px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.modal-save-mountain-button {
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  padding: 0px;
  align-self: flex-start;
  background: none;
  border-width: 0;
  flex-direction: row;
}
.modal-save-mountain-icon {
  width: 34px;
  height: 34px;
}
.modal-save-mountain-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-left: var(--dl-space-space-doubleunit);
  margin-right: var(--dl-space-space-quadruple);
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.modal-save-mountain-text {
  align-self: center;
}
.modal-save-mountain-icon2 {
  fill: var(--dl-color-primary-mainblue);
  width: 50px;
  height: 50px;
  margin: var(--dl-space-space-unit);
}
.modal-save-mountain-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
}
.modal-save-mountain-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.modal-save-mountain-icon4 {
  width: 34px;
  height: 34px;
}
.modal-save-mountain-text1 {
  font-size: 24;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.modal-save-mountain-checkbox {
  width: 34px;
  height: 34px;
  align-self: center;
}
.modal-save-mountain-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
}
.modal-save-mountain-container6 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.modal-save-mountain-icon6 {
  width: 34px;
  height: 34px;
}
.modal-save-mountain-text2 {
  font-size: 24;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.modal-save-mountain-checkbox1 {
  width: 34px;
  height: 34px;
  align-self: center;
}
.modal-save-mountain-button1 {
  color: white;
  font-size: 24px;
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  margin-left: var(--dl-space-space-doubleunit);
  border-width: 0;
  margin-right: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 24px;
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-mainblue);
}

@media(max-width: 767px) {
    .modal-save-mountain-container1 {
      width: 95vw;
      height: 350px;
      padding: var(--dl-space-space-halfunit);
    }
    .modal-save-mountain-button {
      margin: var(--dl-space-space-halfunit);
    }
    .modal-save-mountain-container2 {
      margin: var(--dl-space-space-halfunit);
    }
    .modal-save-mountain-text {
      font-size: 24px;
    }
    .modal-save-mountain-icon2 {
      width: 34px;
      height: 34px;
      margin: var(--dl-space-space-halfunit);
    }
    .modal-save-mountain-container3 {
      padding: var(--dl-space-space-halfunit);
    }
    .modal-save-mountain-text1 {
      font-size: 14px;
    }
    .modal-save-mountain-container5 {
      padding: var(--dl-space-space-halfunit);
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .modal-save-mountain-text2 {
      font-size: 14px;
    }
    .modal-save-mountain-button1 {
      width: 100px;
      height: auto;
      margin-top: var(--dl-space-space-tripleunit);
      font-size: 16px;
      align-self: flex-end;
      text-align: center;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-halfunit);
      padding-right: var(--dl-space-space-halfunit);
      padding-bottom: var(--dl-space-space-halfunit);
    }
  }