.mountain-dashboard-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.mountain-dashboard-header-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  background-image: linear-gradient(90deg, rgb(255, 233, 255) 0.00%,rgb(218, 254, 255) 100.00%);
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.mountain-dashboard-inner-header-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 1500px;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.mountain-dashboard-left {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.mountain-dashboard-icon {
  width: 50px;
  height: 50px;
  margin-top: var(--dl-space-space-unit);
  margin-left: var(--dl-space-space-unit);
  cursor: pointer;
}
.mountain-dashboard-container01 {
  width: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-doubleunit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.mountain-dashboard-text {
  color: var(--dl-color-primary-100);
  font-size: 48px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Poppins;
  font-weight: 700;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.mountain-dashboard-text01 {
  font-size: 1.6rem;
  align-self: flex-start;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-container03 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.mountain-dashboard-icon02 {
  fill: #ffbf01;
  width: 30px;
  height: 30px;
  align-self: flex-start;
  margin-right: var(--dl-space-space-halfunit);
}
.mountain-dashboard-text02 {
  font-size: 1.6rem;
  align-self: flex-start;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-icon04 {
  width: 50px;
  height: 50px;
  margin: var(--dl-space-space-unit);
  align-self: center;
  transition: 0.3s;
}

.mountain-dashboard-right {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
}
.mountain-dashboard-container04 {
  width: auto;
  display: flex;
  align-self: center;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.mountain-dashboard-icon06 {
  fill: var(--dl-color-primary-mainblue);
  width: 50px;
  height: 55px;
  align-self: center;
  transition: 0.3s;
}
.mountain-dashboard-icon06:hover {
  width: 60px;
  height: 60px;
}
.mountain-dashboard-text03 {
  color: #000000;
  font-size: 16px;
  align-self: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-container05 {
  width: auto;
  display: flex;
  align-self: center;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.mountain-dashboard-icon08 {
  width: 50px;
  height: 50px;
  transition: 0.3s;
}
.mountain-dashboard-icon08:hover {
  width: 60px;
  height: 60px;
}
.mountain-dashboard-text04 {
  color: #000000;
  font-size: 16px;
  align-self: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-container06 {
  width: auto;
  display: flex;
  align-self: center;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.mountain-dashboard-icon11 {
  width: 50px;
  height: 50px;
  transition: 0.3s;
}
.mountain-dashboard-icon11:hover {
  width: 60px;
  height: 60px;
}
.mountain-dashboard-text05 {
  color: #000000;
  font-size: 16px;
  align-self: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-dash {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-image: linear-gradient(90deg, rgb(255, 233, 255) 0.00%,rgb(218, 254, 255) 99.00%);
}
.mountain-dashboard-dash1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1500px;
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.mountain-dashboard-container07 {
  width: auto;
  height: auto;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.mountain-dashboard-container08 {
  flex: 0 0 auto;
  width: 500px;
  height: auto;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
}
.mountain-dashboard-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.mountain-dashboard-image {
  width: 300px;
  margin-top: var(--dl-space-space-unit);
  object-fit: cover;
  margin-left: var(--dl-space-space-unit);
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-unit);
}
.mountain-dashboard-text06 {
  color: var(--dl-color-primary-100);
  font-size: 24px;
  align-self: center;
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-container10 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.mountain-dashboard-text07 {
  color: var(--dl-color-primary-100);
  font-size: 20px;
  margin-top: var(--dl-space-space-doubleunit);
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.55;
  margin-left: 0px;
  margin-right: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-text08 {
  color: var(--dl-color-primary-100);
  font-size: 20px;
  margin-top: 0px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.55;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: 0px;
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-text09 {
  color: var(--dl-color-primary-100);
  margin: var(--dl-space-space-doubleunit);
  font-size: 20px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-container11 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mountain-dashboard-text10 {
  font-size: 2rem;
  margin-top: var(--dl-space-space-doubleunit);
  font-family: Poppins;
  font-weight: 700;
  line-height: 1;
  margin-left: var(--dl-space-space-doubleunit);
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-container12 {
  flex: 0 0 auto;
  width: auto;
  height: 100px;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: linear-gradient(to right, #bdc3c7 0%, #2c3e50 100%);
}
.mountain-dashboard-text11 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-unit);
  font-size: 2rem;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-text12 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-unit);
  font-size: 2rem;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-text13 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-unit);
  font-size: 2rem;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
}
.mountain-dashboard-dash2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-image: linear-gradient(90deg, rgb(255, 233, 255) 1.00%,rgb(218, 254, 255) 99.00%);
}
@media(max-width: 1200px) {
    .mountain-dashboard-container07 {
        align-items: center;
    }
  .mountain-dashboard-dash1 {
    flex-wrap: wrap-reverse;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
  }
  .mountain-dashboard-container11 {
    align-items: center;
  }
  .mountain-dashboard-text {
    color: var(--dl-color-primary-100);
    font-size: 20px;
    margin-top: var(--dl-space-space-halfunit);
    font-family: Poppins;
    font-weight: 600;
  }


  .mountain-dashboard-icon {
    width: 30px;
    height: 30px;
    margin: 10px;
  }
  .mountain-dashboard-container01 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-halfunit);
  }
  .mountain-dashboard-text01 {
    font-size: 16px;
  }
  .mountain-dashboard-icon02 {
    width: 24px;
    height: 24px;
  }
  .mountain-dashboard-text02 {
    font-size: 16px;
  }
  .mountain-dashboard-icon04 {
    width: 34px;
    height: 34px;
    margin: 0px;
  }
  .mountain-dashboard-container04 {
    margin: 0px;
  }
  .mountain-dashboard-icon06 {
    width: 34px;
    height: 34px;
  }
  .mountain-dashboard-text03 {
    font-size: 12px;
  }
  .mountain-dashboard-container05 {
    margin: 0px;
    width: 40px;
  }
  .mountain-dashboard-icon08 {
    height: 34px;
    width: 40px;
  }
  .mountain-dashboard-text04 {
    font-size: 12px;
  }
  .mountain-dashboard-container06 {
    margin: 0px;
  }
  .mountain-dashboard-icon11 {
    width: 34px;
    height: 34px;
  }
  .mountain-dashboard-text05 {
    font-size: 12px;
  }
  .mountain-dashboard-container08 {
    width: auto;
    margin: 0px;
    align-self: flex-start;
  }
  .mountain-dashboard-image {
    width: 198px;
    height: 185px;
  }
  .mountain-dashboard-text09 {
    margin: var(--dl-space-space-doubleunit);
  }
  .mountain-dashboard-text10 {
    margin-top: 0px;
    margin-left: 0px;
  }
  .mountain-dashboard-container12 {
    width: 100%;
    margin: 0px;
  }
  .mountain-dashboard-text11 {
    margin: var(--dl-space-space-halfunit);
  }
  .mountain-dashboard-text12 {
    margin: var(--dl-space-space-halfunit);
  }
  .mountain-dashboard-text13 {
    margin: var(--dl-space-space-halfunit);
  }
  .mountain-dashboard-text06 {
    font-size: 20px;
  }
  .mountain-dashboard-text07 {
    font-size: 16px;
  }
  .mountain-dashboard-text08 {
    font-size: 16px;
  }
  .mountain-dashboard-text09 {
    font-size: 16px;
    margin-right: 0px;
  }
  .mountain-dashboard-right {
    margin-right: 0px;
    width: 130px;
    display: flex;
    justify-content: space-between;
  }
  .mountain-dashboard-left {
    /* width: 100%; */
    width: auto;
  }
  .mountain-dashboard-icon04 {
    display: none;
  }
}
