.circle-outline {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: center;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-round);
    flex-direction: column;
    justify-content: center;
    width: 65px;
    height: 65px;
  }

  .smaller-size {
    height: 25px;
    width: 25px;
  }

  .smallest-size {
    height: 25px;
    width: 12px;
  }

  .wide-size {
    width: 36px;
  }

  .small-img {
    height: 25px;
    width: 25px;
    resize: auto;
  }